import { ProductConfig } from '@draftkings/sportsbook-common-contracts';

export type GeneratePlayerURLProps = {
    sport: string;
    league: string;
    name: string;
    id: string | undefined;
    productConfig: ProductConfig;
};

export function generatePlayerURL(props: GeneratePlayerURLProps) {
    const { productConfig, id, name, league, sport } = props;

    if (league && sport && productConfig.playerPageConfig.isEnabled && id) {
        const combinedPlayerDataByDash = `${name}-odds-${id}`.replace(/\s/g, '-');
        const urlPlayerData = encodeURIComponent(combinedPlayerDataByDash);
        return `/players/${sport}/${league}/${urlPlayerData}`.toLowerCase();
    }
    return '';
}
