import { EventHeader } from '../EventHeader';
import React from 'react';
import { ChunkedSubCategoriesProps, IState } from '../../contracts/state';
import { observer } from 'mobx-react';
import { Logos } from '@draftkings/event-page-widget-contracts/src/Logos';
import { Marketboard } from '../Marketboard';
import { ParsedCategory, ParsedEvent } from '../../contracts/parsers/IParser';
import { TabsWrapper, Tab, Content, SubTab, SubTabsWrapper } from '@draftkings/dk-tab-switcher/src/components';
import { ProductConfig, TrackEventFunction } from '@draftkings/sportsbook-common-contracts';
import { SGPTurnOnButton } from '../SGPButtons';
import { PlayerPageContextProvider } from '../../contexts/PlayerPageContext/PlayerPageContext';
import { EventPageError } from '../EventPageError';
import { marketBoardDict } from '../../helpers/dict';
import '@draftkings/component-builder/src/styles/theme.css';
import './index.scss';

type AppProps = {
    dataProvider: IState;
    logos: Logos;
    trackEvent: TrackEventFunction;
    renderLoader: (showOnlyMarketboard: boolean) => JSX.Element;
    consumerVersion: string;
};

type ContentMapArgs = {
    tabId: string;
    controller: IState['tabSwitcherController'];
    subTabs: ParsedCategory[];
    event: ParsedEvent;
    showOverlay: boolean;
    toggleIsExpanded: (subcategoryId: string) => void;
    renderStats: () => JSX.Element;
    renderQuickSgp: () => JSX.Element;
    productConfig: ProductConfig;
    trackEvent: TrackEventFunction;
    isError: boolean;
    isLoaded: boolean;
    isLoading: boolean;
    onRefreshButtonClick: (categoryId: string) => void;
    renderLoader: (showOnlyMarketboard: boolean) => JSX.Element;
    chunkedSubCategories: ChunkedSubCategoriesProps[];
    consumerVersion: string;
};

const contentMap: Record<string, (args: ContentMapArgs) => JSX.Element> = {
    Odds: ({
        tabId,
        controller,
        subTabs,
        event,
        showOverlay,
        toggleIsExpanded,
        productConfig,
        trackEvent,
        isError,
        isLoaded,
        isLoading,
        onRefreshButtonClick,
        renderLoader,
        chunkedSubCategories,
        consumerVersion
    }) => {
        return (
            <React.Fragment key={tabId}>
                {subTabs.map((subTab) => (
                    <Content key={`${tabId}/${subTab.id}`} route={`${tabId}/${subTab.id}`} controller={controller}>
                        {isError && (
                            <EventPageError
                                title={marketBoardDict.onLoadEventErrorTitle}
                                text={marketBoardDict.onLoadEventErrorText}
                                buttonText="Refresh"
                                onClick={() => onRefreshButtonClick(subTab.id)}
                            />
                        )}
                        {event.status !== 'FINISHED' && isLoading && renderLoader(true)}
                        {isLoaded && event.status !== 'FINISHED' && (
                            <Marketboard
                                chunkedSubCategories={chunkedSubCategories}
                                consumerVersion={consumerVersion}
                                toggleIsExpanded={toggleIsExpanded}
                                productConfig={productConfig}
                                trackEvent={trackEvent}
                            >
                                {showOverlay && (
                                    <div className="ep-marketboard-overlay" data-testid="ep-marketboard-overlay"></div>
                                )}
                            </Marketboard>
                        )}
                    </Content>
                ))}
            </React.Fragment>
        );
    },
    'Quick Sgp': ({ tabId, controller, renderQuickSgp }) => {
        return (
            <Content key={tabId} route={tabId} controller={controller}>
                {renderQuickSgp()}
            </Content>
        );
    },
    Stats: ({ tabId, controller, renderStats }) => {
        return (
            <Content key={tabId} route={tabId} controller={controller}>
                {renderStats()}
            </Content>
        );
    }
};

class AppComponent extends React.Component<AppProps, IState> {
    constructor(props: AppProps) {
        super(props);
    }

    render() {
        if (this.props.dataProvider.events.length === 0 && this.props.dataProvider.isError) {
            return (
                <section className="ep-event-page-widget">
                    <div className="ep-event-page-widget__error" data-testid="event-page-widget-error">
                        This event is not available. Go back <a href="/">home</a>.
                    </div>
                </section>
            );
        }
        return (
            <>
                {this.props.dataProvider.events.length === 0 &&
                    !this.props.dataProvider.isSgpEnabled &&
                    this.props.renderLoader(false)}
                {this.props.dataProvider.events.map((event) => (
                    <section className="ep-event-page-widget" key={event.id}>
                        <EventHeader
                            event={event}
                            leagueName={event.leagueName}
                            logosMap={this.props.logos}
                            isTeamSwap={this.props.dataProvider.isTeamSwap}
                            getNavLink={this.props.dataProvider.getNavLink}
                            getTeamLogo={this.props.dataProvider.getTeamLogo}
                            getDateCell={this.props.dataProvider.getDateCell}
                            renderStrapline={this.props.dataProvider.renderStraplineWrapper}
                            staticS3Host={this.props.dataProvider.staticS3Host}
                            isSgpEnabled={this.props.dataProvider.isSgpEnabled}
                        />
                        {this.props.dataProvider.hasSgpToggle && !this.props.dataProvider.isSgpEnabled && (
                            <SGPTurnOnButton
                                infoText={'Click here to enable'}
                                text={'SAME GAME PARLAY'}
                                onClick={this.props.dataProvider.setSgpMode}
                            />
                        )}
                        {!this.props.dataProvider.isSgpEnabled && (
                            <PlayerPageContextProvider
                                value={{
                                    getPlayerLink: this.props.dataProvider.getPlayerLink,
                                    onPlayerLinkClick: this.props.dataProvider.onPlayerLinkClick,
                                    playerPageConfig: this.props.dataProvider.playerPageConfig
                                }}
                            >
                                <TabsWrapper>
                                    {this.props.dataProvider.tabs.map((tab) => (
                                        <Tab
                                            route={tab.id}
                                            key={tab.id}
                                            controller={this.props.dataProvider.tabSwitcherController}
                                            isSelectedByDefault={tab.isSelected}
                                            onClick={() => this.props.dataProvider.onTabClick(tab.name)}
                                            navigationUrl={this.props.dataProvider.buildTabNavigationUrl(tab.name)}
                                        >
                                            {tab.name}
                                        </Tab>
                                    ))}
                                </TabsWrapper>
                                {this.props.dataProvider.tabs.map((tab) => (
                                    <Content
                                        route={tab.id}
                                        key={tab.id}
                                        controller={this.props.dataProvider.tabSwitcherController}
                                    >
                                        {tab.subTabs.length > 0 && (
                                            <SubTabsWrapper>
                                                {tab.subTabs.map((subTab: ParsedCategory) => (
                                                    <SubTab
                                                        route={`${tab.id}/${subTab.id}`}
                                                        key={subTab.id}
                                                        controller={this.props.dataProvider.tabSwitcherController}
                                                        isSelectedByDefault={tab.isSelected && subTab.isSelected}
                                                        onClick={() =>
                                                            this.props.dataProvider.onSubtabClick(
                                                                subTab.id,
                                                                subTab.name
                                                            )
                                                        }
                                                        navigationUrl={this.props.dataProvider.buildTabNavigationUrl(
                                                            tab.name,
                                                            subTab.name
                                                        )}
                                                    >
                                                        <div>{subTab.name}</div>
                                                    </SubTab>
                                                ))}
                                            </SubTabsWrapper>
                                        )}
                                    </Content>
                                ))}
                                {this.props.dataProvider.tabs.map((tab) => {
                                    return contentMap[tab.name]?.({
                                        tabId: tab.id,
                                        controller: this.props.dataProvider.tabSwitcherController,
                                        showOverlay: this.props.dataProvider.showOverlay,
                                        subTabs: tab.subTabs,
                                        event: event,
                                        toggleIsExpanded: this.props.dataProvider.onToggleExpandClick,
                                        renderQuickSgp: this.props.dataProvider.renderQuickSgpWrapper,
                                        renderStats: this.props.dataProvider.renderStatsWrapper,
                                        productConfig: this.props.dataProvider.productConfig,
                                        trackEvent: this.props.trackEvent,
                                        isError: this.props.dataProvider.isError,
                                        isLoaded: this.props.dataProvider.isLoaded,
                                        isLoading: this.props.dataProvider.isLoading,
                                        onRefreshButtonClick: this.props.dataProvider.onRefreshButtonClick,
                                        renderLoader: this.props.renderLoader,
                                        chunkedSubCategories: this.props.dataProvider.chunkedSubCategories,
                                        consumerVersion: this.props.consumerVersion
                                    });
                                })}
                            </PlayerPageContextProvider>
                        )}
                    </section>
                ))}
            </>
        );
    }
}

export const App = observer(AppComponent);
