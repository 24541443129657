import { PlayerPageConfig, PlayerData } from '@draftkings/sportsbook-common-contracts';
import React, { createContext } from 'react';

type PlayerPageContext = {
    children: React.ReactNode;
    value: PlayerPageContextProps;
};

export interface PlayerPageContextProps {
    playerPageConfig: PlayerPageConfig;
    getPlayerLink: (data: PlayerData) => string;
    onPlayerLinkClick: (url: string) => void;
}

/**
 * Context for PlayerPage related data. Used to pass needed properties from the App to the component-builder without prop-drilling.
 */
export const PlayerPageContext = createContext<PlayerPageContextProps | undefined>(undefined);

export const PlayerPageContextProvider: React.FC<PlayerPageContext> = ({ children, value }) => {
    return <PlayerPageContext.Provider value={value}>{children}</PlayerPageContext.Provider>;
};
