import { TabConfig } from '@draftkings/sportsbook-common-contracts';

export function hasTag(tags: string[] | undefined, tag: string): boolean {
    return tags?.some((x) => x.toLowerCase() === tag.toLowerCase()) || false;
}
export function hasPreLivePrePacks(tabConfig: Pick<TabConfig, 'isEnabled'>, tags: string[] | undefined) {
    return tabConfig.isEnabled && hasTag(tags, 'PrePack');
}

export function hasLivePrePacks(tabConfig: Pick<TabConfig, 'isLiveEnabled'>, tags: string[] | undefined) {
    return tabConfig.isLiveEnabled && hasTag(tags, 'LivePrePack');
}

export function hasAnyPrePacks(tabConfig: Pick<TabConfig, 'isEnabled' | 'isLiveEnabled'>, tags: string[] | undefined) {
    return hasPreLivePrePacks(tabConfig, tags) || hasLivePrePacks(tabConfig, tags);
}
