import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { ContractTypes } from '@draftkings/dk-data-layer';
import { IDataIndex, Noop } from '@draftkings/widgets-core/src/contracts';
import { BooleanIndex } from '@draftkings/widgets-core/src/indexes';
import { LocalizationAPI, OddsStyles, SelectionHandlerIDs } from '@draftkings/component-builder/src/contracts';
import { MarketWithSelections, pointsFormatBuilder } from '@draftkings/component-builder/src/builders';
import { POPULAR_TAB, POPULAR_TAB_NAME, SELECTION_TOGGLE_FALLBACK_TIMEOUT, getDefaultValue } from '../../helpers';
import { isCategorySelected, parseEvent } from '../helpers';
import {
    IParser,
    ParsedEvent,
    ParserOptions,
    ParsedCategory,
    BetslipSelectionStatus
} from '../../contracts/parsers/IParser';
import { IEventPageRetriever } from '../../contracts/retrievers/IRetriever';
import { Logos } from '@draftkings/event-page-widget-contracts/src/Logos';
import { EventPageWidgetConfig } from '@draftkings/event-page-widget-contracts/src/EventPageWidgetConfig';
import { SBMessageBus } from '@draftkings/event-page-widget-contracts/src/MessageBus';
import {
    PostAddSelectionPayload,
    RemoveOutcomeFromBetSlipPayload
} from '@draftkings/event-page-widget-contracts/src/MessageBus/Payload';
import { compareBySortOrder } from '../../helpers/compareBySortOrder';
import { noop } from '@draftkings/widgets-core/src/helpers';
import { FeatureFlags, TrackEventFunction } from '@draftkings/sportsbook-common-contracts';

type EventPageParserMobx =
    | 'events'
    | 'categories'
    | 'oddsStyle'
    | 'toggleSelections'
    | 'removeSelections'
    | 'addSelections'
    | 'setCategoryId'
    | 'resetCategory'
    | 'betslipSelections'
    | 'showOverlay'
    | 'collapsedSubcategoryIds'
    | 'tags'
    | 'handleSelectionToggleFallback'
    | 'replaceSelectedSelections';

type ReplacedSelection = Omit<ContractTypes.Selection, 'replacedSelectionId'> & {
    replacedSelectionId: string;
};

export class EventPageParser implements IParser {
    private oddsStyle: OddsStyles;
    private messageBus: SBMessageBus;
    private selectedSelectionIndex: IDataIndex<string, boolean>;
    private loadingSelectionIndex: IDataIndex<string, boolean>;
    private localizationLib: LocalizationAPI;
    private logos: Logos;
    private staticS3Host: string;
    private retriever: IEventPageRetriever;
    betslipSelections: Map<string, BetslipSelectionStatus>;
    private collapsedSubcategoryIds: Set<string>;
    private casesPerStatus: Record<Exclude<BetslipSelectionStatus, 'Removing'>, (id: string) => void>;
    showOverlay: boolean;
    private logError: EventPageWidgetConfig['logError'];
    private replacedSelectionDisposer: Noop;
    private isMobile: () => boolean;
    private onMobileToggleSelection: () => void;
    private featureFlags: FeatureFlags;
    private toggledSelectionsFallbackHandler: Map<string, () => void>;
    private trackEvent: TrackEventFunction;

    constructor(options: ParserOptions) {
        this.retriever = options.retriever;
        this.oddsStyle = options.oddsStyle;
        this.messageBus = options.messageBus;
        this.localizationLib = options.localizationLib;
        this.logos = options.logos;
        this.staticS3Host = options.staticS3Host;
        this.betslipSelections = this.parseBetslipSelection(options.betslipSelections);
        this.showOverlay = false;
        this.collapsedSubcategoryIds = new Set();
        this.toggledSelectionsFallbackHandler = new Map();
        this.logError = options.logError;
        this.replacedSelectionDisposer = noop;
        this.isMobile = options.isMobile;
        this.onMobileToggleSelection = options.onMobileToggleSelection;
        this.featureFlags = options.featureFlags;
        this.trackEvent = options.trackEvent;

        this.casesPerStatus = {
            Adding: (id) => {
                this.betslipSelections.set(id, 'Added');
            },
            Added: (id) => {
                this.betslipSelections.delete(id);
            }
        };

        this.selectedSelectionIndex = new BooleanIndex(
            (selectionId: string) => {
                const status = this.betslipSelections.get(selectionId);
                return status === 'Added' && status;
            },
            () => true
        );

        this.loadingSelectionIndex = new BooleanIndex(
            (selectionId: string) => {
                const status = this.betslipSelections.get(selectionId);
                return status !== 'Added' && status;
            },
            () => true
        );
        makeObservable<typeof this, EventPageParserMobx>(this, {
            events: computed,
            oddsStyle: observable,
            betslipSelections: observable,
            addSelections: action,
            removeSelections: action,
            toggleSelections: action.bound,
            betslipAddedSelectionsHandler: action,
            setCategoryId: action.bound,
            resetCategory: action,
            loadData: action,
            categories: computed,
            tags: computed,
            isTeamSwap: computed,
            showOverlay: observable,
            toggleIsExpanded: action.bound,
            collapsedSubcategoryIds: observable,
            replaceSelectedSelections: action,
            handleSelectionToggleFallback: action
        });
    }

    get tags(): string[] {
        return getDefaultValue(this.retriever.data.events.get(this.retriever.eventId)?.tags, []);
    }

    get categories(): ParsedCategory[] {
        const event = this.retriever.data.events.get(this.retriever.eventId);
        if (!event) {
            return [];
        }

        const hasPopularTab =
            getDefaultValue(event.categories?.slice().length, 0) > 1 &&
            getDefaultValue(event.tags?.includes('FeaturedSubcategory'), false);
        const currentCategories = hasPopularTab
            ? [
                  {
                      id: POPULAR_TAB,
                      name: POPULAR_TAB_NAME,
                      sortOrder: 1,
                      isSelected: !this.retriever.categoryId
                  }
              ]
            : [];
        return [
            ...currentCategories,
            ...getDefaultValue(
                event.categories
                    ?.slice()
                    .sort(compareBySortOrder)
                    .map((category, index) => ({
                        ...category,
                        isSelected: isCategorySelected({
                            index,
                            hasPopularTab,
                            categoryId: category.id,
                            selectedCategoryId: this.retriever.categoryId
                        })
                    })),
                []
            )
        ];
    }

    get isTeamSwap(): boolean {
        const event = this.retriever.data.events.get(this.retriever.eventId);
        const league = event && this.retriever.data.leagues.get(event.leagueId);
        const isTeamSwap = Boolean(league?.isTeamSwap);

        return isTeamSwap;
    }

    toggleIsExpanded(subcategoryId: string) {
        this.collapsedSubcategoryIds.has(subcategoryId)
            ? this.collapsedSubcategoryIds.delete(subcategoryId)
            : this.collapsedSubcategoryIds.add(subcategoryId);
    }

    get events(): Map<string, ParsedEvent> {
        return new Map(
            Array.from(this.retriever.data.events, ([eventId, event]) => [
                eventId,
                parseEvent({
                    event,
                    leagueName: getDefaultValue(this.retriever.data.leagues.get(event.leagueId)?.name, ''),
                    categoriesSortOrderMap: new Map<string, number>(
                        event.categories?.map((category) => [category.id, category.sortOrder])
                    ),
                    marketsWithSelectionsBySubId: this.groupSelectionsToMarketsBySubId(
                        this.retriever.data.markets,
                        this.retriever.data.selections
                    ),
                    isTeamSwap: !!this.retriever.data.leagues.get(event.leagueId)?.isTeamSwap,
                    localization: this.localization,
                    logosMap: this.logos[event.leagueId],
                    staticS3Host: this.staticS3Host,
                    loadingSelectionIndex: this.loadingSelectionIndex,
                    selectedSelectionIndex: this.selectedSelectionIndex,
                    oddsStyle: this.oddsStyle,
                    onSelectionClick: (selectionIds) => this.toggleSelections(selectionIds),
                    collapsedSubcategoryIds: this.collapsedSubcategoryIds,
                    logError: this.logError,
                    featureFlags: this.featureFlags
                })
            ])
        );
    }

    get leagueId(): string {
        const event = this.retriever.data.events.get(this.retriever.eventId);
        return getDefaultValue(event?.leagueId, '');
    }

    get leagueName(): string {
        const event = this.retriever.data.events.get(this.retriever.eventId);
        return getDefaultValue(this.retriever.data.leagues.get(event?.leagueId || '')?.name, '');
    }

    get sportName(): string {
        const event = this.retriever.data.events.get(this.retriever.eventId);
        return getDefaultValue(this.retriever.data.sports.get(event?.sportId || '')?.name, '');
    }

    activate() {
        this.messageBus.on('odds_style_change', this.setOddsStyle, this);
        this.messageBus.on('post_add_selection', this.betslipAddedSelectionsHandler, this);
        this.messageBus.on('remove_selection_from_betslip', this.betslipRemovedSelectionsHandler, this);
        this.messageBus.on('stats_remove_selection', this.betslipRemovedSelectionsHandler, this);
        this.replacedSelectionDisposer = reaction(
            () =>
                [...this.retriever.data.selections.values()].filter(
                    (s): s is ReplacedSelection =>
                        !!s.replacedSelectionId && this.betslipSelections.has(s.replacedSelectionId)
                ),
            this.replaceSelectedSelections
        );
    }

    deactivate() {
        this.messageBus.off('odds_style_change', this.setOddsStyle);
        this.messageBus.off('post_add_selection', this.betslipAddedSelectionsHandler);
        this.messageBus.off('remove_selection_from_betslip', this.betslipRemovedSelectionsHandler);
        this.messageBus.off('stats_remove_selection', this.betslipRemovedSelectionsHandler);
        this.collapsedSubcategoryIds.clear();
        this.replacedSelectionDisposer();
    }

    setOddsStyle = (oddsStyle: OddsStyles) => {
        this.oddsStyle = oddsStyle;
    };

    addSelections = (data: SelectionHandlerIDs): void => {
        this.messageBus.emit('add_selection_to_betslip', {
            providerOutcomeId: data.selectionId
        });
    };

    removeSelections = (data: SelectionHandlerIDs): void => {
        this.messageBus.emit('remove_selection', {
            providerOutcomeId: data.selectionId
        });
    };

    toggleSelections = (data: SelectionHandlerIDs): void => {
        if (this.isMobile()) {
            this.onMobileToggleSelection();
            return;
        }

        const { selectionId } = data;
        const status = this.betslipSelections.get(selectionId);
        const selection = this.retriever.data.selections.get(selectionId);

        if (!selection) {
            return;
        }

        if (status) {
            this.betslipSelections.set(selectionId, 'Removing');
            this.removeSelections(data);
        } else {
            this.betslipSelections.set(selectionId, 'Adding');
            this.addSelections(data);
        }

        this.showOverlay = true;
        this.handleSelectionToggleFallback(selectionId, status);
    };

    handleSelectionToggleFallback(selectionId: string, status: BetslipSelectionStatus | undefined) {
        const timeoutId = setTimeout(() => {
            if (status) {
                this.betslipSelections.set(selectionId, status);
            } else {
                this.betslipSelections.delete(selectionId);
            }

            this.toggledSelectionsFallbackHandler.delete(selectionId);
            this.showOverlay = false;
            this.trackEvent('SELECTION_TOGGLE_TIMED_OUT', {
                eventId: this.retriever.eventId,
                selectionId,
                pageName: 'EVENT_PAGE',
                widget: 'EVENT_PAGE_WIDGET'
            });
        }, SELECTION_TOGGLE_FALLBACK_TIMEOUT);

        this.toggledSelectionsFallbackHandler.set(selectionId, () => clearTimeout(timeoutId));
    }

    cancelSelectionToggleFallback(selectionId: string) {
        const cancelToggleSelectionFallback = this.toggledSelectionsFallbackHandler.get(selectionId);
        cancelToggleSelectionFallback?.();
        this.toggledSelectionsFallbackHandler.delete(selectionId);
    }

    betslipAddedSelectionsHandler(data: PostAddSelectionPayload): void {
        const selectionId = data.providerOutcomeId;

        if (data.isSuccessful) {
            this.casesPerStatus['Adding'](selectionId);
        } else {
            this.casesPerStatus['Added'](selectionId);
        }

        this.cancelSelectionToggleFallback(selectionId);
        this.showOverlay = false;
    }

    betslipRemovedSelectionsHandler(data: RemoveOutcomeFromBetSlipPayload): void {
        const selectionId = String(data.providerOutcomeId);
        const hasIsRemovableProperty = 'isRemovable' in data;

        if (data.isRemovable || !hasIsRemovableProperty) {
            this.casesPerStatus['Added'](selectionId);
        } else {
            this.casesPerStatus['Adding'](selectionId);
        }

        this.cancelSelectionToggleFallback(selectionId);
        this.showOverlay = false;
    }

    setCategoryId = (categoryId: string): void => {
        this.retriever.setCategoryId(categoryId);
        this.collapsedSubcategoryIds.clear();
    };

    resetCategory = () => {
        this.retriever.resetCategory();
        this.collapsedSubcategoryIds.clear();
    };

    loadData = () => {
        this.retriever.loadData();
    };

    private replaceSelectedSelections = (selections: ReplacedSelection[]) => {
        selections.forEach((s) => {
            this.betslipSelections.delete(s.replacedSelectionId);
            this.betslipSelections.set(s.id, 'Added');
        });
    };

    private parseBetslipSelection(
        betslipSelectionsData: EventPageWidgetConfig['betslipSelections']
    ): Map<string, BetslipSelectionStatus> {
        return new Map(betslipSelectionsData.regularSelections.map((s) => [s.id, 'Added']));
    }

    private get localization() {
        return { formatPoints: pointsFormatBuilder(this.localizationLib) };
    }

    private groupSelectionsToMarketsBySubId = (
        markets: Map<string, ContractTypes.Market>,
        selections: Map<string, ContractTypes.Selection>
    ): Record<string, MarketWithSelections[]> => {
        const selectionsByMarketId = new Map<string, Map<string, ContractTypes.Selection>>();
        selections.forEach((selection, selectionId) => {
            const marketSelections = getDefaultValue(selectionsByMarketId.get(selection.marketId), new Map());
            marketSelections.set(selectionId, selection);
            selectionsByMarketId.set(selection.marketId, marketSelections);
        });
        return Array.from(markets.values()).reduce((acc, market) => {
            const marketSelections = getDefaultValue(
                selectionsByMarketId.get(market.id),
                new Map<string, ContractTypes.Selection>()
            );
            const marketWithSelections = {
                ...market,
                selections: marketSelections
            };

            if (market.subcategoryId) {
                if (!acc[market.subcategoryId]) {
                    acc[market.subcategoryId] = [];
                }

                acc[market.subcategoryId].push(marketWithSelections);
            }

            return acc;
        }, {});
    };
}
